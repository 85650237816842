<template>
  <v-card>
    <v-card-title>
      <v-row>
        <v-col cols="auto">
          <v-btn class="label" text color="transparent">
            <v-icon color="#525252" left size="20">$addAdminUser</v-icon>
            <div class="font-weight-regular secondary--text text-capitalize">
              {{ $t('page_admin_users_create_title') }}
            </div>
          </v-btn>
        </v-col>

        <v-spacer></v-spacer>

        <v-col cols="auto">
          <v-btn
            small
            class="text-capitalize font-weight-regular"
            text
            @click="$router.push({ name: 'AdminUsers' })"
          >
            <v-icon color="secondary" left>$goBack</v-icon>
            {{ $t('go_back') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>

    <v-divider></v-divider>

    <validation-observer ref="observer">
      <form @submit.prevent="submit">
        <v-card-text class="custom-card-text">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-row align="center">
                      <v-col
                        v-for="field in fields"
                        :key="field.name"
                        cols="12"
                      >
                        <v-row align="center">
                          <v-col cols="4" md="2" class="text-right label-text">
                            <template v-if="field.type !== 'file'">
                              {{ field.label }}
                            </template>

                            <template v-else>
                              <v-avatar
                                color="grey"
                                class="white--text"
                                size="40"
                              >
                                <v-img
                                  :src="!preview ? file : preview"
                                  width="40"
                                ></v-img>
                              </v-avatar>
                            </template>
                          </v-col>

                          <template
                            v-if="
                              field.type === 'text' || field.type === 'email'
                            "
                          >
                            <v-col
                              cols="8"
                              :md="field.additional_field ? '4' : '8'"
                            >
                              <validation-provider
                                v-slot="{ errors }"
                                :name="field.name"
                                :rules="field.rules"
                              >
                                <v-text-field
                                  outlined
                                  dense
                                  flat
                                  :type="field.type"
                                  v-model="field.value"
                                  :placeholder="field.placeholder"
                                  :error-messages="errors"
                                ></v-text-field>
                              </validation-provider>
                            </v-col>
                            <v-col
                              v-if="field.additional_field"
                              cols="8"
                              :md="field.additional_field ? '4' : '8'"
                              offset="4"
                              offset-md="0"
                            >
                              <validation-provider
                                v-slot="{ errors }"
                                :name="field.additional_field.name"
                                :rules="field.additional_field.rules"
                              >
                                <v-text-field
                                  outlined
                                  dense
                                  flat
                                  v-model="field.additional_field.value"
                                  :placeholder="
                                    field.additional_field.placeholder
                                  "
                                  :error-messages="errors"
                                ></v-text-field>
                              </validation-provider>
                            </v-col>
                          </template>

                          <template v-else-if="field.type === 'file'">
                            <v-col cols="8">
                              <validation-provider
                                v-slot="{ errors }"
                                :name="field.name"
                                :rules="field.rules"
                              >
                                <v-file-input
                                  accept="image/*"
                                  :placeholder="$t('choose_file')"
                                  outlined
                                  dense
                                  flat
                                  prepend-icon=""
                                  :error-messages="errors"
                                  v-model="field.value"
                                  ref="file"
                                  @change="updateFileView"
                                ></v-file-input>
                              </validation-provider>
                            </v-col>
                          </template>

                          <template v-else-if="field.type === 'switch'">
                            <v-col cols="8">
                              <validation-provider
                                v-slot="{ errors }"
                                :name="field.name"
                                :rules="field.rules"
                              >
                                <v-switch
                                  v-model="field.value"
                                  :label="
                                    field.value === 1 ? 'Active' : 'Inactive'
                                  "
                                  :true-value="1"
                                  :false-value="0"
                                  color="#046C21"
                                  :error-messages="errors"
                                ></v-switch>
                              </validation-provider>
                            </v-col>
                          </template>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-row align="center">
                      <v-col cols="12">
                        <v-row align="center">
                          <v-col cols="4" md="2" class="text-right label-text">
                            {{ $t('password') }}
                          </v-col>
                          <v-col cols="8">
                            <validation-provider
                              v-slot="{ errors }"
                              name="password"
                              :rules="passwordRules"
                            >
                              <v-text-field
                                outlined
                                dense
                                flat
                                :type="inputPassword ? 'password' : 'text'"
                                v-model="password"
                                :placeholder="$t('enter_password')"
                                append-icon="mdi-eye"
                                @click:append="toggleInputPassword"
                                :error-messages="errors"
                              ></v-text-field>
                            </validation-provider>
                          </v-col>
                        </v-row>
                      </v-col>

                      <v-col cols="12">
                        <v-row align="center">
                          <v-col cols="4" md="2" class="text-right label-text">
                            {{ $t('confirm_password') }}
                          </v-col>
                          <v-col cols="8">
                            <validation-provider
                              v-slot="{ errors }"
                              name="confirmation"
                            >
                              <v-text-field
                                outlined
                                dense
                                flat
                                :type="inputPassword ? 'password' : 'text'"
                                v-model="passwordConfirmation"
                                :placeholder="$t('confirm_password')"
                                append-icon="mdi-eye"
                                @click:append="toggleInputPassword"
                                :error-messages="errors"
                              ></v-text-field>
                            </validation-provider>
                          </v-col>
                        </v-row>
                      </v-col>

                      <v-col cols="12" md="8" offset-md="2">
                        <v-row>
                          <v-col cols="12">
                            <v-card class="info-card" flat>
                              <v-card-text>
                                <div class="mb-2">
                                  {{
                                    $t('page_users_create_password_hint_text')
                                  }}
                                </div>
                                <ul>
                                  <li>
                                    {{ $t('page_users_create_password_hint_first_rule_text') }}
                                    <v-icon
                                      :color="
                                        containsCorrectLength
                                          ? 'success'
                                          : 'error'
                                      "
                                      size="16"
                                    >
                                      {{
                                        containsCorrectLength
                                          ? 'mdi-check'
                                          : 'mdi-close'
                                      }}
                                    </v-icon>
                                  </li>
                                  <li>
                                    {{ $t('page_users_create_password_hint_second_rule_text') }}
                                    <v-icon
                                      :color="
                                        containsUppercase ? 'success' : 'error'
                                      "
                                      size="16"
                                    >
                                      {{
                                        containsUppercase
                                          ? 'mdi-check'
                                          : 'mdi-close'
                                      }}
                                    </v-icon>
                                  </li>
                                  <li>
                                    {{ $t('page_users_create_password_hint_third_rule_text') }}
                                    <v-icon
                                      :color="
                                        containsLowercase ? 'success' : 'error'
                                      "
                                      size="16"
                                    >
                                      {{
                                        containsLowercase
                                          ? 'mdi-check'
                                          : 'mdi-close'
                                      }}
                                    </v-icon>
                                  </li>
                                  <li>
                                    {{ $t('page_users_create_password_hint_fourth_rule_text') }}
                                    <v-icon
                                      :color="
                                        containsNumber ? 'success' : 'error'
                                      "
                                      size="16"
                                    >
                                      {{
                                        containsNumber
                                          ? 'mdi-check'
                                          : 'mdi-close'
                                      }}
                                    </v-icon>
                                  </li>
                                  <li>
                                    {{ $t('page_users_create_password_hint_fifth_rule_text') }}
                                    <v-icon
                                      :color="
                                        containsSymbol ? 'success' : 'error'
                                      "
                                      size="16"
                                    >
                                      {{
                                        containsSymbol
                                          ? 'mdi-check'
                                          : 'mdi-close'
                                      }}
                                    </v-icon>
                                  </li>
                                </ul>
                              </v-card-text>
                            </v-card>
                          </v-col>
                          <v-col cols="12" class="py-0">
                            <v-row>
                              <v-col v-if="passwordStrength >= 1" cols="4">
                                <v-btn
                                  x-small
                                  rounded
                                  block
                                  class="label elevation-0 password password-weak"
                                ></v-btn>
                              </v-col>
                              <v-col v-if="passwordStrength >= 2" cols="4">
                                <v-btn
                                  x-small
                                  rounded
                                  block
                                  class="label elevation-0 password password-medium"
                                ></v-btn>
                              </v-col>
                              <v-col v-if="passwordStrength >= 3" cols="4">
                                <v-btn
                                  x-small
                                  rounded
                                  block
                                  class="label elevation-0 password password-strong"
                                ></v-btn>
                              </v-col>
                            </v-row>
                          </v-col>

                          <v-col
                            v-if="passwordStrength >= 1"
                            cols="12"
                            class="text-center"
                          >
                            <span
                              :class="`password-${passwordStrengthText}-text`"
                            >
                              Your password is {{ passwordStrengthText }}.
                            </span>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" class="text-center">
                    <v-btn
                      type="submit"
                      :disabled="formStatus"
                      :loading="formStatus"
                      color="primary"
                      class="text-capitalize font-weight-regular"
                      depressed
                      small
                      >Register
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </form>
    </validation-observer>
  </v-card>
</template>

<script>
export default {
  name: 'index',
  computed: {
    businesses() {
      return this.$store.getters.userGetCompanyList
    },
    passwordStrength() {
      if (!this.password) return 0
      let strong = RegExp(
        '^(?=.{14,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*\\W).*$',
        'g'
      )
      let medium = new RegExp(
        '^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})'
      )
      if (strong.test(this.password)) {
        return 3
      } else if (medium.test(this.password)) {
        return 2
      } else {
        return 1
      }
    },
    passwordStrengthText() {
      return this.passwordStrength === 3
        ? this.$t('strong')
        : this.passwordStrength === 2
        ? this.$t('medium')
        : this.$t('weak')
    },
    containsCorrectLength() {
      return this.password.match(/(^.{8,20}$)/)
    },
    containsUppercase() {
      return this.password.match(/([A-Z])/)
    },
    containsLowercase() {
      return this.password.match(/([a-z])/)
    },
    containsNumber() {
      return this.password.match(/([0-9])/)
    },
    containsSymbol() {
      return this.password.match(/[^ A-Za-z0-9]/)
    }
  },
  data() {
    return {
      valid: false,
      inputPassword: true,
      password: '',
      passwordConfirmation: '',
      passwordScore: 0,
      passwordRules: 'required|password|confirmed:confirmation',
      fields: [
        {
          label: this.$t('page_admin_users_create_table_header_name'),
          name: 'last_name',
          type: 'text',
          placeholder: this.$t('page_admin_users_create_table_header_name_last_placeholder'),
          value: '',
          rules: 'required',
          additional_field: {
            type: 'text',
            name: 'first_name',
            placeholder: this.$t('page_admin_users_create_table_header_name_first_placeholder'),
            value: '',
            rules: 'required'
          }
        },
        {
          label: this.$t('page_admin_users_create_table_header_email'),
          name: 'email',
          type: 'text',
          placeholder: this.$t('page_admin_users_create_table_header_email_placeholder'),
          value: '',
          rules: 'required'
        },
        {
          label: this.$t('page_admin_users_create_table_header_profile'),
          name: 'photo',
          type: 'file',
          placeholder: '',
          value: null,
          rules: 'size:5000'
        },
        {
          label: this.$t('page_admin_users_create_table_header_status'),
          name: 'status',
          type: 'switch',
          placeholder: '',
          value: 1,
          rules: 'required'
        }
      ],
      selectedBusiness: null,
      notificationEmail: false,
      passwordEmail: false,
      formStatus: false,
      file: null,
      preview: null
    }
  },
  methods: {
    toggleInputPassword() {
      this.inputPassword = !this.inputPassword
    },

    updateFileView(e) {
      this.preview = URL.createObjectURL(e)
      this.file = e
    },

    async submit() {
      this.formStatus = true
      this.$refs.observer.validate().then(success => {
        if (!success) {
          this.formStatus = false
          return
        }

        const data = new FormData()

        this.fields.forEach(field => {
          if ({}.hasOwnProperty.call(field, 'additional_field')) {
            data.append(
              field.additional_field.name,
              field.additional_field.value
            )
          }
          if (field.type === 'file') data.append(field.name, this.file)
          else data.append(field.name, field.value)
        })

        data.append('password', this.password)

        this.$store
          .dispatch('USER_ADMINS_CREATE', data)
          .then(result => {
            if (result.status === 200) this.$refs.observer.reset()

            this.$router.push({
              name: 'AdminUsers'
            })
          })
          .catch(() => {
            this.formStatus = false
          })
          .finally(() => (this.formStatus = false))
      })
    }
  }
}
</script>

<style lang="scss" src="./style.scss" scoped></style>
